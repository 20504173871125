import { getRiskWorkType as getWorkTypeSelect } from "@/api/psm/risk";

export default {
  data() {
    return {
      workTypeSelect: []
    };
  },
  created() {
    this.getWorkTypes();
  },
  methods: {
    async getWorkTypes() {
      try {
        const list = await getWorkTypeSelect({});
        this.workTypeSelect = list.map(x => {
          return {
            ...x,
            label: x.name,
            value: x.id
          };
        });
      } catch (e) {
        console.log(e);
      }
    }
  }
};
