<template>
  <div class="inspect-record-query">
    <div class="inner">
      <div
        v-for="(item, idx) in configs"
        :key="idx"
        class="inspect-record-query__item"
        @click="onSearchClick(item, idx)"
      >
        <span class="inspect-record-query__item-title">{{ item.title }}</span>
        <van-icon
          name="zhankai"
          class-prefix="iconfont"
          class="inspect-record-query__item-icon"
        />
      </div>
    </div>
    <select-popup
      :choose-value="popupValue"
      :show-filter="false"
      :data="popupData"
      :title="popupTitle"
      :visible="popupVisible"
      :popup-style="{
        height: '50vh'
      }"
      @close="onPopupClose"
      @confirm="onPopupConfirm"
    />
    <cascade-department
      v-model="workDeptCur"
      :visible="departmentVisible"
      include-children
      @close="departmentVisible = false"
      @confirm="refreshQueryByWorkDept"
    />
    <cascade-department
      v-model="deptCheckedCur"
      :visible="departmentVisible2"
      include-children
      title="检查部门"
      @close="departmentVisible2 = false"
      @confirm="refreshQueryByDeptChecked"
    />
  </div>
</template>

<script>
import SelectPopup from "@/components/SelectPopup";
import { inspectClassTypes, isExistProblem } from "../utils/constant";
import { getSelectInspectPerson } from "@/api/psm/workInspect";
import workMixin from "@/views/WorkInspect/mixins/workMixin";
export default {
  name: "InspectRecordQuery",
  components: { SelectPopup },
  props: ["query"],
  mixins: [workMixin],
  data() {
    return {
      queryInfo: {
        deptChecked: "",
        workType: "",
        workDept: "",
        checker: "",
        checkType: "",
        questionStatus: "",
        subDepartment: true
      },
      workDeptCur: "",
      deptCheckedCur: "",
      departmentVisible: false,
      departmentVisible2: false,
      inspectClassTypes,
      isExistProblem,
      inspectPersonList: [],
      configs: [
        {
          title: "作业类别",
          type: "workType",
          types: "workTypeSelect"
        },
        {
          title: "所属部门",
          type: "workDept"
        },
        {
          title: "检查方式",
          type: "checkType",
          types: "inspectClassTypes"
        },
        {
          title: "是否发现问题",
          type: "questionStatus",
          types: "isExistProblem"
        },
        {
          title: "检查部门",
          type: "deptChecked"
        },
        {
          title: "检查人",
          type: "checker",
          types: "inspectPersonList"
        }
      ],
      popupVisible: false,
      popupTitle: "",
      popupData: [],
      selectValue: "",
      popupValue: "",
      popupType: ""
    };
  },
  watch: {
    query(val) {
      this.queryInfo = { ...val };
    }
  },
  created() {
    this.initTypes();
  },
  methods: {
    refreshQueryByWorkDept(val, { include }) {
      this.queryInfo.workDept = val?.id || "";
      this.queryInfo.subDepartment = include;
      this.$emit("refresh", this.queryInfo);
    },
    refreshQueryByDeptChecked(val, { include }) {
      this.queryInfo.deptChecked = val?.id || "";
      this.queryInfo.subDepartment = include;
      this.$emit("refresh", this.queryInfo);
    },
    async initTypes() {
      try {
        const ret = await Promise.all([getSelectInspectPerson()]);
        this.inspectPersonList = ret[0];
      } catch (error) {
        console.log("initTypes -> error", error);
      }
    },
    onSearchClick({ title, type, types }, idx) {
      if (type === "workDept") {
        this.departmentVisible = true;
      } else if (type === "deptChecked") {
        this.departmentVisible2 = true;
      } else {
        this.popupValue = this.queryInfo[type] || "";
        this.popupType = type;
        this.popupData = this[types];
        this.popupTitle = title;
        this.popupVisible = true;
      }
    },
    onPopupReset() {
      this.queryInfo[this.popupType] = "";
      this.popupValue = "";
      this.refreshQueryByWorkDept();
      this.refreshQueryByDeptChecked();
    },
    onPopupClose() {
      this.popupVisible = false;
      this.popupData = [];
    },
    onPopupConfirm(row) {
      this.queryInfo[this.popupType] = row.value;
      this.refreshQueryByWorkDept();
      this.refreshQueryByDeptChecked();
    }
  }
};
</script>

<style lang="scss">
.inspect-record-query {
  overflow: auto;
  .inner {
    width: 546px;
    overflow-x: auto;
    display: flex;
    padding: 12px 18px;
    color: #3b4664;
    font-size: 14px;
    border-bottom: 1px solid #c7c9d0;
  }
  &__item {
    margin-right: 16px;
    &-icon {
      margin-left: 4px;
      color: #aeb3c0;
      font-size: 12px;
    }
  }
  &__item:last-child {
    margin-right: 0;
  }
}
</style>
