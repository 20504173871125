<template>
  <div class="work-inspect-record">
    <div v-if="isList" class="search-query">
      <van-search
        v-model="queryInfo.ticketInfo"
        class="search-bar"
        placeholder="请输入作业名称或编号"
        @clear="onRefresh"
        @search="onRefresh"
      >
        <template #left-icon>
          <van-icon name="sousuo" class-prefix="iconfont" class="search-icon" />
        </template>
      </van-search>
      <date-time
        v-model="dateTime"
        :time-close="false"
        type="date"
        format="{y}-{m}-{d}"
      />
      <inspect-record-query :query="queryInfo" @refresh="onQueryChange" />
      <div class="trouble-total">共{{ total }}条检查记录</div>
    </div>
    <van-pull-refresh
      v-model="isPullRefreshing"
      class="work-inspect-record__wrapper"
      @refresh="onRefresh"
    >
      <van-list
        v-model="isLoading"
        :finished="isFinished"
        :finished-text="isError ? '' : '没有更多了'"
        :error.sync="isError"
        error-text="加载失败，请稍后再试！"
        @load="getList"
      >
        <van-cell
          v-for="(item, idx) in list"
          :key="idx"
          :class="[
            'work-inspect-record__rows',
            idx === list.length - 1 && 'border-none'
          ]"
          :border="false"
          @click="onDetail(item)"
        >
          <div v-if="isList" class="title">{{ item.mixinName }}</div>
          <div class="all-item">
            <div class="line-status">
              <span>{{ item.workTypeName }}</span>
              <span class="tag">{{ item.checkTypeName }}</span>
              <span
                class="tag question"
                :style="{
                  'background-color': questionTagMap[item.questionStatus].color
                }"
                >{{ questionTagMap[item.questionStatus].text }}</span
              >
            </div>
            <div v-if="isList" class="line-department">
              <div>
                <i class="iconfont iconfont-jigou" />
                <span>{{ item.workDeptName }}</span>
              </div>
            </div>
            <div class="line-icon">
              <div>
                <i class="iconfont iconfont-bumen" />
                <span>{{ item.deptCheckName }}</span>
              </div>
              <div>
                <i class="iconfont iconfont-renyuan" />
                <span>{{ item.checkerName }}</span>
              </div>
              <div>
                <i class="iconfont iconfont-riqi" />
                <span>{{ item.ctime }}</span>
              </div>
            </div>
          </div>
          <div class="line-block" />
        </van-cell>
      </van-list>
    </van-pull-refresh>
  </div>
</template>

<script>
import { inspectClassTypes, isExistProblem } from "../utils/constant";
import {
  getPageWorkInspectRecord,
  getSelectInspectPerson
} from "@/api/psm/workInspect";
import dayjs from "dayjs";
import InspectRecordQuery from "@/views/WorkInspect/components/InspectRecordQuery";
import "../styles/component-list.scss";

export default {
  name: "InspectRecord",
  components: { InspectRecordQuery },
  props: {
    ticketId: String,
    mode: {
      type: String,
      default: "list",
      validator: function(value) {
        return ["list", "detail"].indexOf(value) !== -1;
      }
    }
  },
  data() {
    return {
      inspectClassTypes,
      isExistProblem,
      inspectPersonList: [],
      questionDic: { 0: "存在问题", 1: "不存在问题" },
      questionTagMap: {
        0: { text: "存在问题", color: "#fb7171" },
        1: { text: "不存在问题", color: "#3683f4" }
      },
      queryInfo: {
        ticketId: "",
        activeName: 1,
        ticketInfo: "",
        workDept: "",
        checkType: "",
        questionStatus: "",
        beginDate: "",
        endDate: "",
        page: 1,
        size: 15
      },
      list: [],
      total: 0,
      queryCache: {},
      dateTime: { start: "", end: "" },
      // 下拉刷新，上拉加载
      isPullRefreshing: false,
      isLoading: false,
      isFinished: true,
      isError: false
    };
  },
  computed: {
    isList() {
      return this.mode === "list";
    }
  },
  watch: {
    "$route.query": {
      handler(val) {
        this.queryInfo = { ...this.queryCache, ...val };
        this.getList();
      }
    },
    dateTime: {
      handler(val) {
        if (val.start && val.end) {
          this.queryInfo.beginDate = this.formatDate(val.start);
          this.queryInfo.endDate = this.formatDate(val.end);
          this.onRefresh();
        }
        if (!val.start && !val.end) {
          this.queryInfo.beginDate = "";
          this.queryInfo.endDate = "";
          this.onRefresh();
        }
      },
      deep: true
    }
  },
  created() {
    this.getTypes();
    this.queryInfo.ticketId = this.ticketId;
    this.queryInfo.orgCode = this.userInfo.orgCode;
    this.queryCache = { ...this.queryInfo };
    this.queryInfo = { ...this.queryInfo, ...this.$route.query };
    this.initDateQuery();
    // this.onRefresh();
    this.getList();
  },
  methods: {
    initDateQuery() {
      if (this.queryInfo.beginDate && this.queryInfo.endDate) {
        this.dateTime.start = this.queryInfo.beginDate;
        this.dateTime.end = this.queryInfo.endDate;
      } else {
        this.dateTime.start = "";
        this.dateTime.end = "";
      }
    },
    onQueryChange(query) {
      this.queryInfo = { ...this.queryInfo, ...query };
      this.onRefresh();
    },
    formatDate(date) {
      return dayjs(date).format("YYYY-MM-DD");
    },
    onRefresh(init = true) {
      if (init) {
        this.queryInfo.page = 1;
        this.list = [];
      }
      if (this.isList) {
        this.$router.replace({
          path: "/work-inspect-record",
          query: this.queryInfo
        });
      } else {
        this.getList();
      }
    },
    async getList() {
      this.isFinished = false;
      this.isLoading = true;
      if (this.isPullRefreshing) {
        this.isPullRefreshing = false;
      }
      try {
        const data = await getPageWorkInspectRecord(this.queryInfo);
        const { list, total } = data || { list: [], total: 0 };
        this.total = total;
        this.isError = false;
        this.isLoading = false;
        if (list && list.length) {
          this.list = this.list.concat(
            list.map(x => {
              x.mixinName = `${x.ticketName}（${x.ticketCode}）`;
              return x;
            })
          );
          this.queryInfo.page++;
        } else {
          this.isFinished = true;
        }
      } catch (error) {
        console.log("getList -> error", error);
        this.isLoading = false;
        this.isFinished = true;
        this.isError = true;
      }
    },
    async getTypes() {
      try {
        this.inspectPersonList = await getSelectInspectPerson();
      } catch (e) {
        console.log(e);
      }
    },
    onDetail({ id }) {
      this.$router.push({
        name: "WorkInspectRecordDetails",
        params: { id },
        query: { isListPage: this.isList }
      });
    }
  }
};
</script>
