<template>
  <div class="question-list-query">
    <div class="inner">
      <div
        v-for="(item, idx) in configs"
        :key="idx"
        class="question-list-query__item"
        @click="onSearchClick(item, idx)"
      >
        <span class="question-list-query__item-title">{{ item.title }}</span>
        <van-icon
          name="zhankai"
          class-prefix="iconfont"
          class="question-list-query__item-icon"
        />
      </div>
    </div>
    <select-popup
      :choose-value="popupValue"
      :show-filter="false"
      :data="popupData"
      :title="popupTitle"
      :visible="popupVisible"
      :popup-style="{
        height: '50vh'
      }"
      @close="onPopupClose"
      @confirm="onPopupConfirm"
    />
    <cascade-department
      v-model="deptInvolvedCur"
      :visible="departmentVisible"
      title="问题涉及部门"
      include-children
      @close="departmentVisible = false"
      @confirm="questionRefreshQuery"
    />
    <cascade-department
      v-model="deptCheckedCur"
      :visible="departmentVisible2"
      title="检查部门"
      include-children
      @close="departmentVisible2 = false"
      @confirm="refreshQuery"
    />
  </div>
</template>

<script>
import SelectPopup from "@/components/SelectPopup";
import { inspectClassTypes } from "../utils/constant";
import {
  getSelectInspectPerson,
  getSelectInspectProject
} from "@/api/psm/workInspect";
export default {
  name: "QuestionListQuery",
  components: { SelectPopup },
  props: ["query"],
  data() {
    return {
      queryInfo: {
        deptInvolved: "",
        deptChecked: "",
        checker: "",
        checkType: "",
        checkItemId: "",
        subDepartment: true
      },
      deptInvolvedCur: "",
      deptCheckedCur: "",
      departmentVisible: false,
      departmentVisible2: false,
      inspectClassTypes,
      inspectPersonList: [],
      inspectProjectList: [],
      configs: [
        {
          title: "检查项目",
          type: "checkItemId",
          types: "inspectProjectList"
        },
        {
          title: "检查方式",
          type: "checkType",
          types: "inspectClassTypes"
        },
        {
          title: "问题涉及部门",
          type: "deptInvolved"
        },
        {
          title: "检查部门",
          type: "deptChecked"
        },
        {
          title: "检查人",
          type: "checker",
          types: "inspectPersonList"
        }
      ],
      popupVisible: false,
      popupTitle: "",
      popupData: [],
      selectValue: "",
      popupValue: "",
      popupType: ""
    };
  },
  watch: {
    query(val) {
      this.queryInfo = { ...val };
    }
  },
  created() {
    this.initTypes();
  },
  methods: {
    questionRefreshQuery(val, { include }) {
      this.queryInfo.deptInvolved = val?.id || "";
      this.queryInfo.subDepartment = include;
      this.$emit("refresh", this.queryInfo);
    },
    refreshQuery(val, { include, children, childrenIds }) {
      this.queryInfo.deptChecked = val?.id || "";
      this.queryInfo.subDepartment = include;
      this.$emit("refresh", this.queryInfo);
    },
    async initTypes() {
      try {
        const ret = await Promise.all([
          getSelectInspectPerson(),
          getSelectInspectProject()
        ]);
        this.inspectPersonList = ret[0];
        this.inspectProjectList = ret[1];
      } catch (error) {
        console.log("initTypes -> error", error);
      }
    },
    onSearchClick({ title, type, types }, idx) {
      if (type === "deptInvolved") {
        this.departmentVisible = true;
      } else if (type === "deptChecked") {
        this.departmentVisible2 = true;
      } else {
        this.popupValue = this.queryInfo[type] || "";
        this.popupType = type;
        this.popupData = this[types];
        this.popupTitle = title;
        this.popupVisible = true;
      }
    },
    onPopupReset() {
      this.queryInfo[this.popupType] = "";
      this.popupValue = "";
      this.refreshQuery();
    },
    onPopupClose() {
      this.popupVisible = false;
      this.popupData = [];
    },
    onPopupConfirm(row) {
      console.log(row, "row");
      this.queryInfo[this.popupType] = row.value;
      this.refreshQuery();
    }
  }
};
</script>

<style lang="scss">
.question-list-query {
  overflow: auto;
  .inner {
    width: 454px;
    overflow-x: auto;
    display: flex;
    padding: 12px 18px;
    color: #3b4664;
    font-size: 14px;
    border-bottom: 1px solid #c7c9d0;
  }
  &__item {
    margin-right: 16px;
    &-icon {
      margin-left: 4px;
      color: #aeb3c0;
      font-size: 12px;
    }
  }
  &__item:last-child {
    margin-right: 0;
  }
}
</style>
